<template>
	<div class="page-wrapper">
		<div class="list-scroll">
			<div class="teleconsultation-request">
				<section>
					<transition name="fade" mode="out-in" @leave="isTransitioning = true" @enter="isTransitioning = false">
						<router-view />
					</transition>
				</section>
				<transition name="fade">
					<section v-if="$route.name !== 'request-consultants' && !isTransitioning" class="workflow-navigation">
						<community-service-promo style="margin:0 16px 20px 16px" is-rounded />
						<ast-button
							v-if="!isFirstRoute"
							type="default"
							:disabled="isLoading"
							:class="{ disabled: isLoading }"
							@click.native="$router.go(-1)"
						>
							Back
						</ast-button>
						<ast-button
							type="primary"
							:disabled="!canGoNext || isLoading"
							:class="{ disabled: !canGoNext || isLoading }"
							@click.native="goNext"
						>
							<template v-if="isLoading">
								<svg-icon icon="spinner" pulse />
							</template>
							<template v-else-if="['request-submit-report', 'request-submit-image-only'].includes($route.name)">
								Send Request
							</template>
							<template v-else-if="$route.name === 'request-submit-sale'">
								Submit
							</template>
							<template v-else>
								Next
							</template>
						</ast-button>
						<span v-if="isUploading && !canGoNext" class="uploading-message is-muted">
							Please wait for your files to upload &hellip;
						</span>
					</section>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
import store from '@store'
import { mapGetters, mapState } from 'vuex'
import AstButton from '@components/Button'
import CommunityServicePromo from '@components/CommunityServicePromo.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import workflow from '@services/workflow'
import salesService from '@services/salesService'
import { uploadData } from '@services/uploads'


export default {
	name: 'TeleconsultationRequest',
	components: {
		AstButton,
		CommunityServicePromo,
	},
	data() {
		return {
			isTransitioning: false,
			isSubmitted: false,
			workflow,
			uploadData,
		}
	},
	computed: {
		isUploading() {
			return this.uploadData.isUploading('teleconsultation-request')
		},
		canGoNext() {
			return this.workflow.canGoNext
		},
		isLoading() {
			return this.workflow.isLoading
		},
		nextRoute() {
			return this.workflow.nextRoute
		},
		consultants() {
			return this.workflow.consultants
		},
		isFirstRoute() {
			if (this.$route.name === 'request-consultants') return true
			// if only one consultant, the upload route is the first route in the workflow
			if (this.consultants.length !== 1) return false
			return ['request-upload-consultation', 'request-upload-sale'].includes(this.$route.name)
		},
	},
	beforeRouteUpdate(to, from, next) {
		if (!from.query.consultantId && to.query.consultantId) {
			this.workflow.consultantId = to.query.consultantId
		}
		next()
	},
	async beforeRouteEnter(to, from, next) {
		if (to.query.consultantId) {
			workflow.consultantId = to.query.consultantId
		}
		next()
	},
	async beforeRouteLeave(to, from, next) {
		if (this.isSubmitted) return next()
		// If adding a consultant from a consultant URL, continue (will not actually navigate)
		if (to.name === 'community-referral-login') return next()
		// If abandoning progress, confirm
		const isRequestInProgress =
			uploadData.haveUploadsForContext('teleconsultation-request') ||
			(from.query && from.query.consultantId && from.query.studyId)
		// if logging out, do not prompt since user will have already been prompted
		if (this.$store.getters.isAuthenticated && isRequestInProgress) {
			const warning = 'Your submission is not complete.  Are you sure you want to discard your progress?'
			if (!(await showConfirm(warning))) return next(false)
		}
		next()
		uploadData.stopUploads({ context: 'teleconsultation-request' })
		salesService.reset()
		workflow.reset()
	},
	methods: {
		async goNext() {
			if (workflow.lastRoute !== this.$route.name) {
				return this.$router.push(this.nextRoute)
			}

			this.isSubmitted = await workflow.submitCB()
			if (this.isSubmitted === true) {
				// delete the studyId query param to prevent Community "start over" confirmation prompts
				const query = { ...this.$route.query }
				delete query.studyId
				this.$router.replace({ query })
				this.$router.replace(this.nextRoute) // prevent Back navigation
			} else {
				this.$router.push(this.nextRoute)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.teleconsultation-request {
	min-height: 100%;
}
.teleconsultation-request > *:first-child {
	min-height: 30vh;
}
.uploading-message {
	font-size: 0.8em;
	font-style: italic;
	white-space: nowrap;
}
.workflow-navigation {
	padding: 20px 0;
	margin: 0 -16px;
	& > * {
		margin-left: 16px;
		min-width: 96px;
	}
}
</style>
