<template>
	<banner v-if="show && !isMobileOS" type="info" v-bind="$attrs" @learn-more="learnMore()">
		Want to DICOM Send directly from your imaging modality?
	</banner>
</template>

<script>
import Banner from './Banner.vue'
import { openDicomServiceDlg } from '@dialogs/DicomServiceDlg.vue'
import { dicomServiceData } from '@services/dicomServiceData'

export default {
	name: 'CommunityServicePromo',
	components: {
		Banner,
	},
	data() {
		return {}
	},
	computed: {
		show() {
			if (!this.$store.getters.isCommunityUser) return false
			const hasActiveServices = dicomServiceData.dicomServices.some(s => !s.disabled)
			return !hasActiveServices && this.$route.meta.showServicePromo
		},
	},
	methods: {
		learnMore() {
			openDicomServiceDlg()
		},
	},
}
</script>
